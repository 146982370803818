<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Multiselect from "vue-multiselect";
import { organizationService } from "../../../../services/super-user.service";

import BusinessConfigs from "./businessConditionsConfig";

import { notificationMethods } from "@/state/helpers";

import { required, numeric, email, alphaNum } from "vuelidate/lib/validators";

const initForm = {
  name: null,
  address: null,
  phoneNumber: null,
  emailAddress: null,
  orgType: null,
  serviceOptions: null,
  createdBy: JSON.parse(localStorage.getItem("user")).id,
};

const initUserForm = {
  firstName: null,
  lastName: null,
  nationalId: null,
  phoneNumber: null,
  email: null,
  address: null,
  role: null,
  roleId: null,
  password: "pass@123",
  createdBy: JSON.parse(localStorage.getItem("user")).id,
  shopId: null,
};

export default {
  page: {
    title: "Organizations ",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    VuePhoneNumberInput,
    BusinessConfigs,
    Multiselect,
  },
  data() {
    return {
      tableData: [],
      title: "Organizations",
      items: [
        {
          text: "Smart Remit",
          href: "/",
        },
        {
          text: "Organizations",
          active: true,
        },
      ],

      //TODO: Get services from API
      serviceOptions: [{id:1,title:"Wiremit Remittances"}, {id:2,title:"Ria"}, {id:3,title:"Western Union"}, {id:4,title:"Bill Payments"}],
      serviceOptionsSelected: [],

      notificationType: "",
      noticationIcon: "",
      submitted: false,
      submitform: false,
      submit: false,
      showform: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10],
      filter: null,
      action: "",
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selectedOrganization: {},
      showUserForm: false,
      orgTypes: [
        { id: "super-agent", title: "Super Agent" },
        { id: "basic-agent", title: "Basic Agent" },
      ],
      fields: [
        { key: "logoLink", sortable: true, label: "#" },
        { key: "name", label: "Organization Name", sortable: true },
        { key: "organizationType", label: "Type", sortable: true },
        { key: "address", label: "Address", sortable: true },
        { key: "phoneNumber", sortable: true },
        // { key: "emailAddress", sortable: true },
        { key: "actions" },
      ],


      form: {
        name: "",
        address: "",
        phoneNumber: null,
        organizationType: "",
        serviceOptions: [],
        logoLink:"",
        createdBy: JSON.parse(localStorage.getItem("user")).id,
      },

      adminUserForm: {
        firstName: null,
        lastName: null,
        email: null,
        password: "pass@123",
        phoneNumber: null,
        nationalId: null,

        //TODO: Depends on organization type
        role: "super-admin",
        roleId: -1,
        userType: "agent",

        //TODO:from organization created
        shopId: 1,

        address: "",
        createdBy: JSON.parse(localStorage.getItem("user")).id,
      },

      editForm: {
        id: null,
        name: null,
        address: null,
        phoneNumber: null,
        emailAddress: null,
        orgType: null,
        serviceOptions: [],
        createdBy: JSON.parse(localStorage.getItem("user")).id,
      },
    };
  },

  validations: {
    form: {
      name: { required },
      phoneNumber: { required, numeric },
      address: { required },
      serviceOptions: { required },
    },
    adminUserForm: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      phoneNumber: { required, numeric },
      nationalId: { required, alphaNum },
      role: { required },
      shopId: { required },
      address: { required },
    },
  },

  created() {
    this.loadAllOrganizations();
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
      return this.$store ? this.$store.state.notification : null;
    },
  },

  mounted() {
    this.totalRows = this.items.length;
  },

  methods: {
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    submitOrganizationForm() {
      this.submitted = true;
      this.$v.$touch();

      //TODO: Remove this
      this.showUserForm = true;
      this.showform = false;
      //END HERE

      if (this.$v.$invalid) {
        this.submitted = false;
        return;
      } else {
        const { form } = this;

        var svcOptions = [];
        this.values.forEach((serviceValue) => {
          svcOptions.push(serviceValue.id);
        });

        form.serviceOptions = svcOptions;

        const organizationForm = {
          operation: "organizationCreation",
          accessToken: JSON.parse(localStorage.getItem("access_token")),
          requestBody: form,
        };

        organizationService
          .registerOrganization(organizationForm)
          .then((result) => {
            if (result.status == "SUCCESS") {
              this.loadAllOrganizations();
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.showUserForm = true;
              this.showform = false;
              this.form = Object.assign({}, initForm);
            } else {
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    submitSuperAdminForm() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        const { form } = this;
        form.roleId = form.role.id;
        form.role = "super-admin";
        form.phoneNumber = this.phonenumberResults;

        const userForm = {
          operation: "registration",
          accessToken: JSON.parse(localStorage.getItem("access_token")),
          requestBody: form,
        };

        this.registeruser(userForm).then((result) => {
          if (result.status == "SUCCESS") {
            this.notificationType = "success";
            this.noticationIcon = "mdi-check-all";
            this.submitted = false;
            this.form = Object.assign({}, initUserForm);
          } else {
            this.submitted = false;
            this.notificationType = "danger";
            this.noticationIcon = "mdi-block-helper";
          }
        });
      }
    },

    storeState(organization, action) {
      this.editForm.serviceOptions = organization.item.systemFunctions;
      this.editForm = organization.item;
      this.selectedOrganization = organization.item;
      this.action = action;
    },

    disableOrganization(organization, status) {
      this.editForm = organization.item;
      this.editForm.isActive = !status;
      this.editOrganization();
    },

    editOrganization() {
      this.submitted = true;
      const { editForm } = this;
      var svcOptions = [];
      this.editForm.forEach((serviceValue) => {
        svcOptions.push(serviceValue.id);
      });

      editForm.serviceOptions = svcOptions;

      const organizationForm = {
        operation: "Eidt organization ",
        accessToken: JSON.parse(localStorage.getItem("access_token")),
        requestBody: editForm,
      };

      organizationService.editOrganization(organizationForm).then((result) => {
        if (result.status == "SUCCESS") {
          this.loadAllOrganizations();
          this.notificationType = "success";
          this.noticationIcon = "mdi-check-all";
          this.submitted = true;
          this.editForm = Object.assign({}, initForm);
        } else {
          this.submitted = false;
          this.notificationType = "danger";
          this.noticationIcon = "mdi-block-helper";
        }
      });
    },

    async loadAllOrganizations() {
      try {
        await organizationService.getAllOrganizations().then((response) => {
          if (response.responseBody.length > 0) {
            this.tableData = response.responseBody;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <b-modal
        id="modal-standard"
        title="Edit Organization details & ervices"
        title-class="font-18"
        hide-footer
      >
        <div class="card-body">
          <form class="needs-registration" @submit.prevent="editOrganization">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom01">Organization Name</label>
                  <input
                    id="validationCustom01"
                    v-model="editForm.name"
                    type="text"
                    class="form-control"
                    placeholder="Branch Name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="validationCustom02">Contact Number</label>
                  <div>
                    <input
                      id="validationCustom02"
                      v-model="editForm.phoneNumber"
                      type="number"
                      class="form-control"
                      placeholder="Enter phone number"
                    />
                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom04">Physical Address</label>
                  <input
                    id="validationCustom03"
                    v-model="editForm.address"
                    type="text"
                    class="form-control"
                    placeholder="Physical Address"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="validationCustom03">Services </label>
                  <div>
                    <multiselect
                      v-model="editForm.serviceOptions"
                      :options="serviceOptions"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="false"
                      placeholder="Select services"
                      label="title"
                      track-by="title"
                      :preselect-first="true"
                    >
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>

            <div class="spinner" v-if="submitted">
              <i class="ri-loader-line spin-icon"></i>
            </div>
            <button class="btn btn-primary" type="submit">Save changes</button>
          </form>
        </div>
      </b-modal>

      <b-modal
        id="modal-config"
        size="lg"
        title="Configure business conditions"
        title-class="font-18"
        hide-footer
      >
        <BusinessConfigs />
      </b-modal>

      <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >

              <template #cell(logoLink)="row">
                  <a :href="`/#/admin/detailed-single-organization/${row.item.id}`" target="_blank" rel="noopener noreferrer">
                    <img :src="row.item.logoLink" alt="Image" style="width: 100px; height: auto;" />
                  </a>
                </template>

                <template #cell(actions)="row">

                  
                  <a
                    @click="storeState(row, 'businessConditions')"
                    href="javascript:void(0);"
                    class="mr-3 text-success"
                    v-b-tooltip.hover
                    title="Configure business conditions"
                  >
                    <i
                      v-b-modal.modal-config
                      class="ri-settings-2-line font-size-18"
                    ></i>
                  </a>

                  <!-- EDIT ORG -->
                  <a
                    @click="storeState(row, 'edit')"
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    title="Edit organization"
                  >
                    <i
                      v-b-modal.modal-standard
                      class="mdi mdi-pencil font-size-18"
                    ></i>
                  </a>

                  <!-- DISABLE ORG -->
                  <a
                    v-if="row.item.isActive"
                    @click="disableOrganization(row, true)"
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    title="Disable organization"
                  >
                    <i class="mdi mdi-cancel font-size-18"></i>
                  </a>

                  <a
                    v-if="!row.item.isActive"
                    @click="disableOrganization(row, false)"
                    href="javascript:void(0);"
                    class="text-success"
                    v-b-tooltip.hover
                    title="Enable Organization"
                  >
                    <i class="mdi mdi-check font-size-18"></i>
                  </a>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-5">
        <b-alert
          :variant="notificationType"
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi ' + noticationIcon + ' mr-2'"></i
          >{{ notification.message }}
        </b-alert>
        <a
          href="javascript:void(0);"
          class="btn btn-primary mb-2"
          @click="showform = true"
          v-if="!showform && !showUserForm"
        >
          Add New Organization
        </a>

        <!-- ORGANIZATION FORMS -->
        <div class="card" v-if="showform">
          <div class="card-header">
            <h5>New Organization</h5>
          </div>
          <div class="card-body">
            <form
              class="needs-registration"
              @submit.prevent="submitOrganizationForm"
            >
              <div class="row">
                <div class="col-md-7">
                  <div class="form-group">
                    <label for="validationCustom01">Organization Name</label>
                    <input
                      id="validationCustom01"
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Organization Name"
                      :class="{
                        'is-invalid': submitted && $v.form.name.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.name.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="form-group">
                    <label>Organization Type</label>
                    <select
                      class="form-control"
                      v-model="form.organizationType"
                      :class="{
                        'is-invalid': submitted && $v.form.organizationType.$error,
                      }"
                    >
                      <option
                        v-for="orgType in orgTypes"
                        :value="orgType"
                        :key="orgType.id"
                      >
                        {{ orgType.title }}
                      </option>
                    </select>
                    <div
                      v-if="submitted && $v.form.organizationType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.organizationType.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Contact Number</label>
                    <div>
                      <input
                        id="validationCustom02"
                        v-model="form.phoneNumber"
                        type="number"
                        class="form-control"
                        placeholder="Enter phone number"
                        :class="{
                          'is-invalid': submitted && $v.form.phoneNumber.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.form.phoneNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.phoneNumber.required"
                          >This value is required.</span
                        >
                        <span v-if="!$v.form.phoneNumber.numeric"
                          >This value should be a valid phone number.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom04">Physical Address</label>
                    <input
                      id="validationCustom03"
                      v-model="form.address"
                      type="text"
                      class="form-control"
                      placeholder="Physical Address"
                      :class="{
                        'is-invalid': submitted && $v.form.address.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.form.address.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.address.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom03">Services </label>
                    <div>
                      <multiselect
                        v-model="serviceOptionsSelected"
                        :options="serviceOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="false"
                        placeholder="Select services"
                        label="title"
                        track-by="title"
                        :preselect-first="true"
                      >
                      </multiselect>
                      <div
                        v-if="submitted && $v.form.description.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.form.description.required"
                          >Please select permission is required.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" v-if="!submitted">
                  <button class="btn btn-primary" type="submit">
                    Next (1/2)
                  </button>
                </div>
                <div class="col-md-6">
                  <div class="spinner" v-if="submitted">
                    <i class="ri-loader-line spin-icon"></i>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <!-- ORGANIZATION USER FORMS -->
        <div class="card" v-if="showUserForm">
          <div class="card-body">
            <h4 class="card-title">Admin Profile Creation</h4>
            <p class="card-title-desc">
              Register system user for created organization.
            </p>
            <form class="needs-registration" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom01">First name</label>
                    <input
                      id="validationCustom01"
                      v-model="adminUserForm.firstName"
                      type="text"
                      class="form-control"
                      placeholder="First name"
                      :class="{
                        'is-invalid':
                          submitted && $v.adminUserForm.firstName.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.adminUserForm.firstName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.adminUserForm.firstName.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom02">Last name</label>
                    <input
                      id="validationCustom02"
                      v-model="adminUserForm.lastName"
                      type="text"
                      class="form-control"
                      placeholder="Last name"
                      :class="{
                        'is-invalid':
                          submitted && $v.adminUserForm.lastName.$error,
                      }"
                    />
                    <div
                      v-if="submitted && $v.adminUserForm.lastName.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.adminUserForm.lastName.required"
                        >This value is required.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="validationCustom04">ID Number</label>
                    <div>
                      <input
                        id="validationCustom04"
                        v-model="adminUserForm.nationalId"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Number"
                        :class="{
                          'is-invalid':
                            submitted && $v.adminUserForm.nationalId.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.adminUserForm.nationalId.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.adminUserForm.nationalId.required"
                          >This value is required.</span
                        >
                        <span v-if="!$v.adminUserForm.nationalId.alphaNum"
                          >This value should be a valid ID Number.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom05">Phone Number</label>
                    <div>
                      <VuePhoneNumberInput
                        default-country-code="ZW"
                        required
                        v-model="adminUserForm.phoneNumber"
                        @update="onUpdatePhonenumber"
                        :class="{
                          'is-invalid': $v.adminUserForm.phoneNumber.$error,
                        }"
                      />

                      <div
                        v-if="submitted && $v.adminUserForm.phoneNumber.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.adminUserForm.phoneNumber.required"
                          >This value is required.</span
                        >
                        <span v-if="!$v.adminUserForm.phoneNumber.numeric"
                          >This value should be a valid phone number.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label>E-Mail</label>
                    <div>
                      <input
                        v-model="adminUserForm.email"
                        type="email"
                        name="email"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            submitted && $v.adminUserForm.email.$error,
                        }"
                        placeholder="Enter a valid e-mail"
                      />
                      <div
                        v-if="submitted && $v.adminUserForm.email.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.adminUserForm.email.required"
                          >This value is required.</span
                        >
                        <span v-if="!$v.adminUserForm.email.email"
                          >This value should be a valid email.</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="spinner" v-if="submitted">
                <i class="ri-loader-line spin-icon"></i>
              </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">
                Create Profile (2/2)
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>